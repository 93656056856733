import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { match } from "styled-is";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

// Components
import { GridContainer } from "../common";

const SyledSingleImage = styled(GridContainer)`
  > div {
    grid-column: 1 / span 14;
  }
  ${match("wrapped", true)`
    > div {
      grid-column:2 / span 12; 
    }      
  `}
  @media screen and (min-width: 1024px) {
    ${match("wrapped", true)`
      > div {
        grid-column:3 / span 10; 
      }      
    `}
  }
`;
// Animation
const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const SingleImage = ({ wrapped, image, spacing }) => {
  const { padTop, padBot } = spacing || {};
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <SyledSingleImage
      ref={ref}
      wrapped={wrapped}
      padBottom={padBot}
      padTop={padTop}
    >
      <motion.div
        animate={inView ? "visible" : "hidden"}
        initial={false}
        variants={variants}
        transition={{
          type: "ease-out",
          damping: 10,
          delay: 0.3,
          duration: 0.5,
        }}
      >
        {image.asset.fluid && <Img fluid={image.asset.fluid} />}
      </motion.div>
    </SyledSingleImage>
  );
};
