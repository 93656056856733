import React from "react";
import styled from "styled-components";
import { match } from "styled-is";
import Img from "gatsby-image";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

// Components
import { GridContainer, StyledHeading } from "../common";

import { SanityPortableText } from "../sanity";

const StyledImageText = styled(GridContainer)`
  grid-row-gap: 2rem;
  > div:nth-of-type(1) {
    grid-column: 2 / span 12;
  }
  > div:nth-of-type(2) {
    grid-column: 2 / span 12;
    grid-row: 2;
  }
  @media screen and (min-width: 1024px) {
    > div:nth-of-type(1) {
      grid-column: ${match("isWrapped", true) ? 2 : 1} / span
        ${match("isWrapped", true) ? 6 : 7};
      grid-row: 1;
    }
    > div:nth-of-type(2) {
      grid-column: 9 / span 5;
      grid-row: 1;
    }
    .sanity-portable-text div > *:last-child {
      margin-bottom: 0;
    }
    > * {
      grid-row: 1;
      align-self: center;
    }
    ${match("imagePos", "right")`
      > div:nth-of-type(1) {
        grid-column: 8 / span ${match("isWrapped", true) ? 6 : 7};
        grid-row: 1;
      }
      > div:nth-of-type(2) {
        grid-column: 2 / span 5;
        grid-row: 1;
      }
    `}
  }
`;
// Animation
const imageVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const ImageText = ({
  wrapped,
  anchorTarget,
  _rawTextContent,
  image,
  heading,
  imagePosition,
  spacing,
}) => {
  const { padTop, padBot } = spacing || {};
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });
  return (
    <StyledImageText
      isWrapped={wrapped}
      id={anchorTarget}
      ref={ref}
      padBottom={padBot}
      padTop={padTop}
      imagePos={imagePosition}
    >
      {/* <img className="topo" src={Topo} alt="Projects" /> */}
      <AnimatePresence exitBeforeEnter>
        <motion.div
          animate={inView ? "visible" : "hidden"}
          initial={false}
          variants={imageVariants}
          transition={{
            type: "ease-out",
            damping: 10,
            delay: 0.3,
            duration: 0.5,
          }}
        >
          <div>{image && <Img fluid={image.asset.fluid} />}</div>
        </motion.div>
        <motion.div
          animate={inView ? "visible" : "hidden"}
          initial={false}
          variants={imageVariants}
          transition={{
            type: "ease-out",
            damping: 10,
            delay: 0.5,
            duration: 0.5,
          }}
        >
          <section>
            {heading && (
              <StyledHeading as="h4" size="h4">
                {heading}
              </StyledHeading>
            )}
            {_rawTextContent && <SanityPortableText text={_rawTextContent} />}
          </section>
        </motion.div>
      </AnimatePresence>
    </StyledImageText>
  );
};
