import React from "react";
import styled from "styled-components";

// Sanity Config
import BlockContent from "@sanity/block-content-to-react";

// Commponents
import { StyledHeading } from "../common";
import { ButtonLink } from "./buttonLink";
import { ExternalLink } from "./externalLink";
import { InternalLink } from "./internalLink";
import { InternalAnchorLink } from "./internalAnchorLink";
import { ArticleQuote } from "./articleQuote";

const StyledBlockQuote = styled(StyledHeading)`
  display: grid;
  grid-row-gap: 5rem;

  &:before {
    content: "";
    width: 200px;
    height: 2px;
    background: var(--textColor);
    justify-self: center;
  }
  &:after {
    content: "";
    width: 200px;
    height: 2px;
    background: var(--textColor);
    justify-self: center;
  }
`;

const BlockRenderer = (props) => {
  const { style = "normal" } = props.node;

  if (/^h\d/.test(style)) {
    return (
      <StyledHeading as={style} size={style}>
        {props.children}
      </StyledHeading>
    );
  }

  if (style === "blockquote") {
    return (
      <StyledBlockQuote size="h4" fontReg center measure noMargin>
        {props.children}
      </StyledBlockQuote>
    );
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const textCenter = (props) => {
  return (
    <span
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {props.children}
    </span>
  );
};

const fileLink = (props) => {
  return (
    <a href={props.mark.file.asset.url} rel="noreferrer" target="_blank">
      {props.children}
    </a>
  );
};

const emailLink = (props) => {
  return <a href={"mailto:" + props.mark.emailAddress}>{props.children}</a>;
};

const internalLink = (props) => {
  return <InternalLink linkText={props.children} page={props.mark.reference} />;
};

const internalAnchorLink = (props) => {
  return (
    <InternalAnchorLink linkText={props.children} page={props.mark.link} />
  );
};

const link = (props) => {
  return (
    <ExternalLink
      linkText={props.children}
      isBlank={props.mark.blank}
      page={props.mark.href}
    />
  );
};

export const Serializers = {
  types: {
    block: BlockRenderer,
    buttonLink: ({ node }) => <ButtonLink {...node} />,
    articleQuote: ({ node }) => <ArticleQuote {...node} />,
  },
  marks: { textCenter, fileLink, emailLink, internalLink, link },
};
