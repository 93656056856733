import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useEmblaCarousel } from "embla-carousel/react";

// import { setupWheelGestures } from "embla-carousel-wheel-gestures"

const StyledStandardCarousel = styled.div`
  ${(props) => props.wrapped && `overflow: hidden;`}
`;

const StyledCarouselCanvas = styled.div`
  display: flex;
`;

const StyledNav = styled.nav`
  text-align: center;
  margin-top: 2rem;
  button {
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 15px;
    box-sizing: border-box;
    background: rgba(12, 68, 37, 0.28);
    margin: 0 6px;
    cursor: pointer;
    transition: opacity 0.2s;
    label {
      font-size: 0;
      display: none;
    }
    &.is-selected {
      background: var(--green);
    }
  }
`;

const DotButton = ({ selected, onClick }) => (
  <button
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  >
    <label>{selected}</label>
  </button>
);

export const StandardCarousel = ({ children, wrapped }) => {
  const [emblaRef, embla] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: "start",
    containScroll: true,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla,
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <StyledStandardCarousel ref={emblaRef} wrapped={wrapped}>
      <StyledCarouselCanvas className="canvas">{children}</StyledCarouselCanvas>
      <StyledNav>
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      </StyledNav>
    </StyledStandardCarousel>
  );
};
