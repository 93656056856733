import React from "react";
import styled from "styled-components";
import is from "styled-is";

import clientConfig from "../../client-config";

// Import Components
import BlockContent from "@sanity/block-content-to-react";
import { Serializers } from "./serializers";

// Styles
const StyledPortableText = styled.div`
  font-size: var(--bodyText);
  line-height: var(--lhStandard);
  p {
    margin-bottom: 0.3em;
  }
  figure {
    margin-left: 0;
    margin-right: 0;
  }
  ${is("measureText")`
    p{
      max-width: var(--mStandard);
    }
    h1,h2,h3,h4,h5,h6 {
      max-width: var(--mHeading);
    }
  `}
  ${is("centerText", "measureText")`
    p,h1,h2,h3,h4,h5,h6{
      margin-left: auto;
      margin-right: auto;
    }    
  `}
  ${is("centerText")`
    text-align: center;
    ul,
    ol {
      list-style-position:inside;
      margin-left:0;
    }
  `}
`;

export const SanityPortableText = ({ text, measureText, centerText }) => {
  return (
    <StyledPortableText
      className="sanity-portable-text"
      measureText={measureText}
      centerText={centerText}
    >
      <BlockContent
        blocks={text}
        serializers={Serializers}
        {...clientConfig.sanity}
      />
    </StyledPortableText>
  );
};
