import React from "react";

// Hooks
import { useSanityPath } from "../../hooks";
import { StyledLink } from "../common";

export const ButtonLink = ({ page, linkText, ...props }) => {
  const path = useSanityPath(page);
  return (
    <StyledLink {...props} to={path} path={path} className="buttonLink">
      {linkText}
    </StyledLink>
  );
};
