import React from "react";
import styled from "styled-components";
import { match } from "styled-is";
import Img from "gatsby-image";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";
import { NetlifyFormEmbed } from "../forms/netlifyFormEmbed";
import { GridContainer, StyledHeading } from "../common";
import { SanityPortableText } from "../sanity";

const StyledFormText = styled(GridContainer)`
  background: var(--bgDark);
  grid-row-gap: 2rem;
  > div:nth-of-type(1) {
    grid-column: 1 / span 14;
  }
  > div:nth-of-type(2) {
    grid-column: 2 / span 12;
    grid-row: 2;
  }
  .gatsby-image-wrapper > div {
    padding-bottom: 100% !important;
  }
  .sanity-portable-text {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 1024px) {
    > div:nth-of-type(1) {
      grid-column: 1 / span 6;
      grid-row: 1;
    }
    > div:nth-of-type(2) {
      grid-column: 8 / span 6;
      grid-row: 1;
    }
    .sanity-portable-text div > *:last-child {
      margin-bottom: 0;
    }
    > * {
      grid-row: 1;
      align-self: center;
    }
    ${match("imagePos", "right")`
      > div:nth-of-type(1) {
        grid-column: 9 / span 6;
        grid-row: 1;
      }
      > div:nth-of-type(2) {
        grid-column: 2 / span 6;
        grid-row: 1;
      }
    `}
  }
  @media screen and (min-width: 1260px) {
    > div:nth-of-type(1) {
      grid-column: 1 / span 8;
      grid-row: 1;
    }
    > div:nth-of-type(2) {
      grid-column: 10 / span 4;
      grid-row: 1;
    }
    .sanity-portable-text div > *:last-child {
      margin-bottom: 0;
    }
    > * {
      grid-row: 1;
      align-self: center;
    }
    ${match("imagePos", "right")`
      > div:nth-of-type(1) {
        grid-column: 7 / span 8;
        grid-row: 1;
      }
      > div:nth-of-type(2) {
        grid-column: 2 / span 4;
        grid-row: 1;
      }
    `}
  }
`;
// Animation
const imageVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const FormImage = ({
  _rawTextContent,
  image,
  heading,
  imagePosition,
  form,
  spacing,
}) => {
  const { padTop, padBot } = spacing || {};
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });
  return (
    <StyledFormText
      id="pageForm"
      ref={ref}
      padBottom={padBot}
      padTop={padTop}
      imagePos={imagePosition}
    >
      <AnimatePresence>
        <motion.div
          animate={inView ? "visible" : "hidden"}
          initial={false}
          variants={imageVariants}
          transition={{
            type: "ease-out",
            damping: 10,
            delay: 0,
            duration: 0.3,
          }}
        >
          <div>{image.asset.fluid && <Img fluid={image.asset.fluid} />}</div>
        </motion.div>
        <motion.div
          animate={inView ? "visible" : "hidden"}
          initial={false}
          variants={imageVariants}
          transition={{
            type: "ease-out",
            damping: 10,
            delay: 0.5,
            duration: 0.5,
          }}
        >
          <section>
            {heading && (
              <StyledHeading as="h4" size="h4">
                {heading}
              </StyledHeading>
            )}
            {_rawTextContent && <SanityPortableText text={_rawTextContent} />}
            {form && <NetlifyFormEmbed {...form} />}
          </section>
        </motion.div>
      </AnimatePresence>
    </StyledFormText>
  );
};
