import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

// Components
import { StyledHeading, StyledAnchorLink, GridContainer } from "../common";

import { SanityPortableText } from "../sanity";

const StyledTextBanner = styled(GridContainer)`
  padding: 8rem 0 6rem;
  h1 {
    font-size: calc(30px + (52 - 30) * ((100vw - 320px) / (1800 - 320)));
    grid-column: 2 / span 12;
  }
  section {
    grid-column: 2 / span 12;
  }
  @media screen and (min-width: 768px) {
    padding: 14vh 0;
    h1 {
      grid-column: 2 / span 5;
      display: flex;
      align-self: center;
      justify-self: center;
    }
    section {
      grid-column: 7 / span 7;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 24vh 0;
    h1 {
      grid-column: 2 / span 6;
    }
    section {
      grid-column: 8 / span 6;
    }
  }
`;
// Animation
const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const textVariants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 60,
  },
};

export const BannerText = ({
  heading,
  anchorTarget,
  anchorText,
  _rawTextContent,
}) => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <>
      <StyledTextBanner ref={ref}>
        <AnimatePresence exitBeforeEnter>
          {heading && (
            <StyledHeading as="h1">
              <motion.div
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={variants}
                transition={{
                  type: "ease-out",
                  damping: 10,
                  delay: 0.3,
                  duration: 0.6,
                }}
              >
                {heading}
              </motion.div>
            </StyledHeading>
          )}
          <section>
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={textVariants}
              transition={{
                type: "ease-out",
                damping: 10,
                delay: 0.5,
                duration: 0.7,
              }}
            >
              {_rawTextContent && <SanityPortableText text={_rawTextContent} />}
              {anchorTarget ? (
                <>
                  {anchorText && (
                    <StyledAnchorLink href={"#" + anchorTarget}>
                      {anchorText}
                    </StyledAnchorLink>
                  )}
                </>
              ) : (
                <>
                  {anchorText && (
                    <StyledAnchorLink href={`#bannerAnchor`}>
                      {anchorText}
                    </StyledAnchorLink>
                  )}
                </>
              )}
            </motion.div>
          </section>
        </AnimatePresence>
      </StyledTextBanner>
      <div id="bannerAnchor"></div>
    </>
  );
};
