import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Hooks
import { useSanityPath } from "../../hooks";

// Styles
const StyledPageLink = styled(Link)`
  color: inherit;
  font-weight: 500 !important;
  text-decoration: underline;
  font-family: var(--bodyFont) !important;
`;

export const InternalLink = ({ page, linkText, ...props }) => {
  const path = useSanityPath(page);

  return (
    <StyledPageLink {...props} to={path}>
      {linkText}
    </StyledPageLink>
  );
};
