import React from "react";
import styled from "styled-components";

import { StyledHeading } from "../common";

// Styles
const StyledQuote = styled.section`
  color: inherit;
  text-decoration: none;
  margin: 3rem 0;
  h4 {
    margin-bottom: 2rem;
  }
  span {
    font-size: 60px;
    text-align: center;
    line-height: 1;
    display: block;
    color: var(--green1);
    &:nth-of-type(2) {
      margin-top: 2rem;
    }
  }
`;

export const ArticleQuote = ({ quoteName, quoteText, ...props }) => {
  return (
    <StyledQuote {...props}>
      <span>“</span>
      <StyledHeading as="h4" size="h4" center fontBold>
        {quoteText}
      </StyledHeading>
      <StyledHeading as="h5" size="h6" center>
        {quoteName}
      </StyledHeading>
      <span>”</span>
    </StyledQuote>
  );
};
