import React from "react";
import PropTypes from "prop-types";
import { BannerContact } from "./bannerContact";
import { BannerHero } from "./bannerHero";
import { BannerText } from "./bannerText";
import { BannerLanding } from "./bannerLanding";
import { BioCarousel } from "./bioCarousel";
import { BioList } from "./bioList";
import { FormImage } from "./formImage";
import { HeadingText } from "./headingText";
import { ImageText } from "./imageText";
import { Partners } from "./partners";
import { ImageTextCarousel } from "./imageTextCarousel";
import { ImageTextGrid } from "./imageTextGrid";
import { SingleImage } from "./singleImage";
import { TabbedContent } from "./tabbedContent";
import { TextBlock } from "./textBlock";
import { TextBlockTwoCol } from "./textBlockTwoCol";
import { Quote } from "./quote";
import { VideoBlock } from "./videoBlock";

export const BlockZone = ({ zoneBlocks }) => {
  const { blocks } = zoneBlocks || [];
  // Map blocks to return correct type
  const block = blocks.map((block) => {
    switch (block._type) {
      case "bannerContact":
        return <BannerContact {...block} />;
      case "bannerHero":
        return <BannerHero {...block} />;
      case "bannerText":
        return <BannerText {...block} />;
      case "bannerLanding":
        return <BannerLanding {...block} />;
      case "bioCarousel":
        return <BioCarousel {...block} />;
      case "bioList":
        return <BioList {...block} />;
      case "formImage":
        return <FormImage {...block} />;
      case "headingText":
        return <HeadingText {...block} />;
      case "imageText":
        return <ImageText {...block} />;
      case "partners":
        return <Partners {...block} />;
      case "imageTextCarousel":
        return <ImageTextCarousel {...block} />;
      case "imageTextGrid":
        return <ImageTextGrid {...block} />;
      case "singleImage":
        return <SingleImage {...block} />;
      case "tabbedContent":
        return <TabbedContent {...block} />;
      case "textBlock":
        return <TextBlock {...block} />;
      case "textBlockTwoCol":
        return <TextBlockTwoCol {...block} />;
      case "quote":
        return <Quote {...block} />;
      case "videoBlock":
        return <VideoBlock {...block} />;

      default:
        return null;
    }
  });
  return <>{block}</>;
};

BlockZone.propTypes = {
  zoneBlocks: PropTypes.array.isRequired,
};
