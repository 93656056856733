import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";
import { useWindowSize } from "../../hooks";
import Topo from "../../images/topo-projects.png";
import { StyledHeading, GridContainer, StyledLink } from "../common";
import { StandardCarousel } from "../carousels";
import { match } from "styled-is";

const StyledItemCarousel = styled(GridContainer)`
  ${match("isBgDark", true)`
    background: var(--bgDark);
`}

  .topo {
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    ${match("isBgImage", false)`
      display: none;
  `}
  }
  aside {
    z-index: 1;
    grid-row: 1;
    grid-column: 2 / span 12;
    text-align: center;
    padding: 3rem 0;
    a {
      font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1800 - 320)));
    }
  }
  section {
    z-index: 2;
    overflow: hidden;
    grid-row: 2;
    grid-column: 1 / span 14;
    aside {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    .topo {
      width: 60%;
    }
    section {
      grid-row: 1;
      grid-column: 1 / span 14;
      margin-left: -2rem;
      nav {
        display: none;
      }
      aside {
        margin-left: 2rem;
        padding: 0 0 0 2rem;
        width: 30vw;
        flex: 0 0 auto;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        h3 {
          max-width: 250px;
        }
      }
      .canvas {
        margin-left: -2rem;
      }
    }
  }
`;

const ItemCard = styled.div`
  width: 100%;
  flex: 0 0 auto;
  padding: 0 1rem;
  .gatsby-image-wrapper > div {
    padding-bottom: 116% !important;
  }
  h6 {
    font-size: 14px;
    margin-top: 2rem;
  }
  h4 {
    font-size: calc(22px + (27 - 22) * ((100vw - 320px) / (1800 - 320)));
    margin: 1rem 0;
  }
  aside {
    display: none;
  }
  @media screen and (min-width: 768px) {
    padding: 0;
    width: 27vw;
    padding: 0 0 0 2rem;
  }
`;

const HeadingSection = ({ heading, link }) => {
  return (
    <aside>
      {heading && (
        <StyledHeading as="h3" size="h3">
          {heading}
        </StyledHeading>
      )}
      {link?.page?.main?.slug?.current && (
        <StyledLink block to={`/${link.page.main.slug.current}`}>
          {link.linkText}
        </StyledLink>
      )}
    </aside>
  );
};

// Animation
const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const ImageTextCarousel = ({
  heading,
  link,
  items,
  spacing,
  isBgDark,
  isBgImage,
}) => {
  const { padTop, padBot } = spacing || {};
  const { width } = useWindowSize();
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <StyledItemCarousel
      padBottom={padBot}
      padTop={padTop}
      ref={ref}
      isBgDark={isBgDark}
      isBgImage={isBgImage}
    >
      <img className="topo" src={Topo} alt="Topo" />
      {width < 768 && <HeadingSection heading={heading} link={link} />}
      <section>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            animate={inView ? "visible" : "hidden"}
            initial={false}
            variants={variants}
            transition={{
              type: "ease-out",
              damping: 10,
              delay: 0.3,
              duration: 0.5,
            }}
          >
            <StandardCarousel>
              {items &&
                items.map((item, index) => {
                  const pathLink = (_type) => {
                    switch (_type) {
                      case "caseStudy":
                        return `/projects/${item?.main?.slug?.current}`;
                      case "blogs":
                        return `/blogs/${item?.main?.slug?.current}`;
                      default:
                        return `/${item?.main?.slug?.current}`;
                    }
                  };

                  return (
                    <>
                      {index === 0 && (
                        <HeadingSection heading={heading} link={link} />
                      )}
                      <ItemCard>
                        {item?.main?.slug && (
                          <Link to={pathLink(item._type)}>
                            {item.featuredImage && (
                              <Img fluid={item.featuredImage.asset.fluid} />
                            )}
                            {item.category && (
                              <StyledHeading as="h6" fontReg bold uppercase>
                                {item.category}{" "}
                                {item._type === "blogs" && (
                                  <>— {item.blogDate}</>
                                )}
                              </StyledHeading>
                            )}
                            {item.main.title && (
                              <StyledHeading as="h4">
                                {item.main.title}
                              </StyledHeading>
                            )}
                            {item.shortDesc && <p>{item.shortDesc}</p>}
                          </Link>
                        )}
                      </ItemCard>
                    </>
                  );
                })}
            </StandardCarousel>
          </motion.div>
        </AnimatePresence>
      </section>
    </StyledItemCarousel>
  );
};
