import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

// Hooks
import { useWindowSize } from "../../hooks";

import Topo from "../../images/topo-projects.png";

// Components
import { StyledHeading, GridContainer } from "../common";

import { SanityPortableText } from "../sanity";

import { StandardCarousel } from "../carousels";

const StyledBioCarousel = styled(GridContainer)`
  background: var(--bgDark);
  .topo {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  aside {
    grid-row: 1;
    grid-column: 2 / span 12;
    text-align: center;
    padding: 3rem 0;
  }
  section {
    z-index: 2;
    overflow: hidden;
    grid-row: 2;
    grid-column: 1 / span 14;
    aside {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    .topo {
      width: 60%;
    }
    section {
      grid-row: 1;
      grid-column: 1 / span 14;
      margin-left: -2rem;
      nav {
        display: none;
      }
      aside {
        margin-left: 2rem;
        padding: 0 0 0 2rem;
        width: 30vw;
        flex: 0 0 auto;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        h3 {
          max-width: 200px;
        }
      }
      .canvas {
        margin-left: -2rem;
      }
    }
  }
`;

const BioCard = styled.div`
  width: 100%;
  flex: 0 0 auto;
  padding: 0 1rem;
  .gatsby-image-wrapper > div {
    padding-bottom: 116% !important;
  }
  .placeholder {
    width: 100%;
    display: block;
    padding-bottom: 116% !important;
    background: var(--darkGreen);
  }
  h6 {
    font-size: 14px;
    margin-top: 1rem;
  }
  h4 {
    font-size: 22px;
    margin: 1rem 0;
  }
  aside {
    display: none;
  }
  @media screen and (min-width: 768px) {
    padding: 0;
    width: 27vw;
    padding: 0 0 0 2rem;
  }
`;

const HeadingSection = ({ heading }) => {
  return (
    <aside>
      {heading && (
        <StyledHeading as="h3" size="h3">
          {heading}
        </StyledHeading>
      )}
    </aside>
  );
};

export const BioCarousel = ({ heading, items, spacing }) => {
  const { padTop, padBot } = spacing || {};

  const { width } = useWindowSize();

  return (
    <StyledBioCarousel padBottom={padBot} padTop={padTop}>
      <img className="topo" src={Topo} alt="Projects" />
      {width < 768 && <HeadingSection heading={heading} />}
      <section>
        <StandardCarousel>
          {items &&
            items.map((item, index) => {
              const { name, role, image, _rawBio } = item;

              return (
                <>
                  {index === 0 && <HeadingSection heading={heading} />}
                  <BioCard>
                    {image ? (
                      <Img fluid={image.asset.fluid} />
                    ) : (
                      <div className="placeholder"></div>
                    )}
                    {role && (
                      <StyledHeading as="h6" fontReg bold uppercase>
                        {role}
                      </StyledHeading>
                    )}
                    {name && <StyledHeading as="h4">{name}</StyledHeading>}
                    {_rawBio && <SanityPortableText text={_rawBio} />}
                  </BioCard>
                </>
              );
            })}
        </StandardCarousel>
      </section>
    </StyledBioCarousel>
  );
};
