import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { StyledHeading, FlexContainer, GridContainer } from "../common";
import { motion, AnimatePresence } from "framer-motion";
import Arrow from "../../svg/down-arrow.svg";
import Pagination from "../../utils/pagination";

const StyledItemGrid = styled(GridContainer)`
  > div {
    grid-row: 2;
    grid-column: 2 / span 12;
  }
  .item-section {
    grid-column-end: 14;
    justify-self: end;
    text-align: center;
    position: relative;
  }

  .sort-button {
    display: flex;
    width: fit-content;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    margin-bottom: 1rem;
    p {
      font-weight: 500;
      padding-top: 5px;
    }
  }

  .dropdown {
    background: white;
    position: absolute;
    top: 1rem;
    right: -20%;
    color: var(--green);
    text-align: center;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;

    @media screen and (min-width: 1024px) {
      right: -40%;
    }

    button {
      width: min-content;
      @media screen and (min-width: 1024px) {
        width: max-content;
        transition: all 0.25s ease;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    > div {
      justify-content: ${(props) => (props.centerItems ? "center" : "left")};
      gap: 1.5rem;
      grid-column: 2 / span 12;
    }
  }
`;

const StyledItemCard = styled.div`
  width: 100%;
  margin-bottom: 4rem;
  span {
    display: block;
    position: relative;
    &:hover div {
      opacity: 1;
    }
  }
  .gatsby-image-wrapper > div {
    padding-bottom: 116% !important;
  }
  h6 {
    font-size: 14px;
    margin: 2rem 0 1rem;
  }
  h4 {
    font-size: 22px;
  }
  @media screen and (min-width: 768px) {
    padding: 0;
    width: 48%;
  }
  @media screen and (min-width: 1024px) {
    width: calc((100% / 3) - 1rem);
  }
`;
const StyledHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgb(12 68 37 / 50%);
  color: white;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
`;

const Sort = ({ onClick, options, defaultOption }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <StyledItemGrid>
      <div className="item-section">
        <div
          className="sort-button"
          onClick={() => {
            setShowDropdown((prev) => !prev);
          }}
        >
          <p>{defaultOption}</p>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: showDropdown ? 180 : 0 }}
            transition={{ duration: 0.2 }}
            className="arrow-container"
          >
            <Arrow />
          </motion.div>
        </div>
        <AnimatePresence>
          {showDropdown && (
            <motion.div
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 20 }}
              exit={{ opacity: 0, y: 0 }}
              transition={{ duration: 0.2 }}
              className="dropdown"
            >
              {options.map((option, i) => (
                <button
                  key={i}
                  onClick={() => (
                    onClick({ target: { value: option.value } }),
                    setShowDropdown(false)
                  )}
                  value={option.value}
                >
                  {option.title}
                </button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </StyledItemGrid>
  );
};

export const ImageTextGrid = ({ spacing, items, contentType }) => {
  const { padTop, padBot } = spacing || {};
  const [sortMethod, setSortMethod] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfItems = items?.length;

  const sortTypes = [
    { title: "Latest Articles", value: "latestArticles" },
    { title: "Oldest Articles", value: "oldestArticles" },
  ];

  const sortDocuments = (a, b) => {
    if (sortMethod === "oldestArticles") {
      return new Date(a?.blogDate) - new Date(b?.blogDate);
    }
    if (sortMethod === "latestArticles") {
      return new Date(b?.blogDate) - new Date(a?.blogDate);
    }
    return 0;
  };

  /// sort items by date
  const sortedItems = items?.sort(sortDocuments);

  /// set items to be displayed on the current page (e.g 9 items per page)
  const itemsPerPage = 9;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const blogItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  /// check if there are less than 3 blog items in each page (for centering purposes)
  const centerItems = blogItems.length < 3;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {contentType === "blog" && numberOfItems > 2 && (
        <Sort
          onClick={(e) => setSortMethod(e.target.value)}
          defaultOption="SORT BY"
          options={sortTypes}
        />
      )}
      <StyledItemGrid
        padBottom={padBot}
        padTop={padTop}
        centerItems={centerItems}
      >
        <FlexContainer>
          {(contentType === "blog" ? blogItems : items).map((item, index) => {
            const pathLink = (_type) => {
              switch (_type) {
                case "caseStudy":
                  return `/projects/${item?.main?.slug?.current}`;
                case "blogs":
                  return `/blogs/${item?.main?.slug?.current}`;
                default:
                  return `/${item?.main?.slug?.current}`;
              }
            };

            return (
              <>
                <StyledItemCard key={index}>
                  {item?.main?.slug && (
                    <Link to={pathLink(item._type)}>
                      {item.featuredImage && (
                        <span>
                          <StyledHover>
                            View {contentType === "blog" ? "Blog" : "Project"}
                          </StyledHover>
                          <Img fluid={item.featuredImage.asset.fluid} />
                        </span>
                      )}

                      {item?.category && (
                        <StyledHeading as="h6" fontReg bold uppercase>
                          {item.category}{" "}
                          {item._type === "blogs" && <>— {item.blogDate}</>}
                        </StyledHeading>
                      )}
                      {item?.main?.title && (
                        <StyledHeading as="h4">{item.main.title}</StyledHeading>
                      )}
                      {item?.shortDesc && <p>{item.shortDesc}</p>}
                    </Link>
                  )}
                </StyledItemCard>
              </>
            );
          })}
        </FlexContainer>
      </StyledItemGrid>
      {contentType === "blog" && numberOfItems > 9 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};
