import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";

// Components
import { FlexContainer } from "../common";

import { SanityPortableText } from "../sanity";

const StyledBannerLarge = styled(FlexContainer)`
  height: 100vh;
  position: relative;
  align-content: center;
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .mask {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  section {
    padding: 0 1rem;
    z-index: 2;
    .gatsby-image-wrapper {
      position: relative !important;
      width: 70%;
      height: auto;
      margin-bottom: 20px;
    }
    p {
      color: white;
      font-family: var(--headingFont);
      font-size: var(--h2);
      line-height: 1.4;
    }
    a {
      z-index: 1;
      color: white;
      font-size: 20px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  @media screen and (min-width: 768px) {
    section {
      padding: 0 3rem;
      padding-top: 0;
      .gatsby-image-wrapper {
        margin-bottom: 50px;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    height: 100vh;
    section {
    }
  }
  @media screen and (min-width: 1200px) {
    section {
      padding: 0 5rem;
    }
  }
`;
const BackToSite = styled(Link)`
  display: flex;
  align-items: center;
  line-height: 1;
  text-decoration: none;
  svg {
    margin-right: 10px;
    width: 16px;
  }
  span {
    color: white;
  }
  @media screen and (min-width: 768px) {
    span {
      margin-bottom: -4px;
      line-height: 20px;
    }
    svg {
      width: 27px;
    }
  }
`;

const BannerHeader = styled(FlexContainer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 2rem 1rem;
  font-size: 14px;
  a.toBottom {
    color: white;
    line-height: 1;
    background: #a77e52;
    border-radius: 50px;
    padding: 10px 20px 5px;
  }
  @media screen and (min-width: 768px) {
    font-weight: medium;
    padding: 3rem;
    font-size: 20px;
    a {
    }
    a.toBottom {
      padding: 20px 35px 15px;
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 5rem;
  }
`;

export const BannerLanding = ({
  contactText,
  enticerText,
  _rawHeading,
  image,
  logo,
}) => {
  return (
    <>
      <StyledBannerLarge>
        <BannerHeader center="xs" between="xs">
          <BackToSite to="/">
            <svg
              width="27"
              height="21"
              viewBox="0 0 27 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.7244 19.0329L11.6479 18.1012L4.59557 11.2414L25.0067 11.0033L24.9918 9.72836L4.5807 9.96644L11.4711 2.94393L10.5261 2.03405L2.00081 10.6341L10.7244 19.0329Z"
                fill="white"
                stroke="white"
              />
            </svg>
            <span>Back to site</span>
          </BackToSite>
          {contactText && (
            <AnchorLink className="toBottom" href={`#pageForm`}>
              {contactText}
            </AnchorLink>
          )}
        </BannerHeader>
        {image && (
          <>
            <Img fluid={image.asset.fluid} />
            <div className="mask"></div>
          </>
        )}
        <section>
          {logo && <Img fluid={logo.asset.fluid} />}
          {_rawHeading && <SanityPortableText text={_rawHeading} />}
          {enticerText && (
            <>
              <AnchorLink href={`#bannerAnchor`}>{enticerText}</AnchorLink>
            </>
          )}
        </section>
      </StyledBannerLarge>
      <div id="bannerAnchor"></div>
    </>
  );
};
