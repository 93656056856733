import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

import Topo from "../../images/topo-projects.png";

// Components
import { StyledHeading, GridContainer } from "../common";

const StyledBioCarousel = styled(GridContainer)`
  background: var(--bgDark);

  .topo {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  aside {
    z-index: 2;
    grid-row: 1;
    grid-column: 2 / span 12;
    text-align: center;
    padding: 3rem 0;
  }
  section {
    z-index: 2;
    grid-row: 2;
    grid-column: 2 / span 12;
  }
  @media screen and (min-width: 768px) {
    .topo {
      width: 60%;
    }
    aside {
      grid-row: 1;
      grid-column: 2 / span 3;
      flex: 0 0 auto;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      h3 {
        max-width: 200px;
      }
    }
    section {
      grid-row: 1;
      grid-column: 6 / span 8;
    }

    section > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (min-width: 1000px) {
    aside {
      grid-column: 2 / span 4;
    }
    section {
      grid-column: 7 / span 7;
    }
  }
`;

const BioCard = styled.div`
  width: 100%;
  display: grid;
  padding: 0 1rem;
  h6 {
    font-size: 14px;
    margin-bottom: 0;
  }
  h4 {
    font-size: 22px;
    margin: 0.4rem 0 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

// Animation
const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const BioList = ({ heading, items, spacing }) => {
  const { padTop, padBot } = spacing || {};

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <StyledBioCarousel padBottom={padBot} padTop={padTop} ref={ref}>
      <AnimatePresence exitBeforeEnter>
        <img className="topo" src={Topo} alt="Projects" />
        <aside>
          {heading && (
            <StyledHeading as="h3" size="h3">
              {heading}
            </StyledHeading>
          )}
        </aside>
        <section>
          <motion.div
            animate={inView ? "visible" : "hidden"}
            initial={false}
            variants={variants}
            transition={{
              type: "ease-out",
              damping: 10,
              delay: 0.3,
              duration: 0.5,
            }}
          >
            {items &&
              items.map((item, index) => {
                const { name, role } = item;

                return (
                  <>
                    <BioCard>
                      {role && (
                        <StyledHeading as="h6" fontReg bold uppercase>
                          {role}
                        </StyledHeading>
                      )}
                      {name && <StyledHeading as="h4">{name}</StyledHeading>}
                    </BioCard>
                  </>
                );
              })}
          </motion.div>
        </section>
      </AnimatePresence>
    </StyledBioCarousel>
  );
};
