import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";
import { NetlifyFormEmbed } from "../forms/netlifyFormEmbed";
import { GridContainer, StyledHeading } from "../common";
import { SanityPortableText } from "../sanity";

const StyledFormText = styled(GridContainer)`
  padding: 8rem 0 6rem;
  grid-row-gap: 2rem;
  aside {
    grid-column: 2 / span 12;
  }
  section {
    grid-column: 2 / span 12;
    grid-row: 2;
  }
  @media screen and (min-width: 768px) {
    padding: 14vh 0;
    aside {
      grid-column: 2 / span 5;
      grid-row: 1;
    }
    section {
      grid-column: 7 / span 7;
      grid-row: 1;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 24vh 0;
  }
`;
// Animation
const imageVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const BannerContact = ({ _rawTextContent, heading, form }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });
  return (
    <StyledFormText ref={ref}>
      <AnimatePresence exitBeforeEnter>
        <aside>
          <motion.div
            animate={inView ? "visible" : "hidden"}
            initial={false}
            variants={imageVariants}
            transition={{
              type: "ease-out",
              damping: 10,
              delay: 0.3,
              duration: 0.5,
            }}
          >
            {heading && (
              <StyledHeading as="h4" size="h4">
                {heading}
              </StyledHeading>
            )}
            {_rawTextContent && <SanityPortableText text={_rawTextContent} />}
          </motion.div>
        </aside>
        <section>
          <motion.div
            animate={inView ? "visible" : "hidden"}
            initial={false}
            variants={imageVariants}
            transition={{
              type: "ease-out",
              damping: 10,
              delay: 0.5,
              duration: 0.5,
            }}
          >
            {form && <NetlifyFormEmbed {...form} />}
          </motion.div>
        </section>
      </AnimatePresence>
    </StyledFormText>
  );
};
