import React from "react";
import styled from "styled-components";

const PaginationDiv = styled.div`
  display: flex;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  justify-content: center;
  gap: 32px;
  margin-bottom: 10rem;

  @media screen and (max-width: 480px) {
    gap: 18px;
  }

  button {
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
  }

  .active {
    font-weight: 500;
  }
`;

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const currentPageIndex = pageNumbers.indexOf(currentPage);
  const startIndex = Math.max(0, currentPageIndex - 2);
  const endIndex = Math.min(totalPages, startIndex + 4);
  const displayPageNumbers = pageNumbers.slice(startIndex, endIndex);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <PaginationDiv>
      {pageNumbers.includes(currentPage - 1) && (
        <button
          onClick={() => {
            scrollToTop();
            handlePageChange(currentPage - 1);
          }}
        >
          Previous
        </button>
      )}

      {displayPageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => {
            scrollToTop();
            handlePageChange(number);
          }}
          className={currentPage === number ? "active" : ""}
        >
          {number}
        </button>
      ))}

      {pageNumbers.includes(currentPage + 1) && (
        <button
          onClick={() => {
            scrollToTop();
            handlePageChange(currentPage + 1);
          }}
        >
          Next
        </button>
      )}
    </PaginationDiv>
  );
};

export default Pagination;
