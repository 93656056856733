import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimateSharedLayout } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEmblaCarousel } from "embla-carousel/react";
import { useWindowSize } from "../../hooks";
import { StyledHeading, GridContainer, StandardContainer } from "../common";
import { SanityPortableText } from "../sanity";

const StyledTabbedContent = styled(GridContainer)`
  background: var(--bg);
  overflow: hidden;
  article {
    grid-column: 1 / span 14;
  }
  h6 {
    font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1800 - 320)));
    grid-column: 2 / span 12;
    letter-spacing: 0.05em;
    margin-bottom: 2rem;
  }
  .sanity-portable-text {
    padding: 0 1.2rem;
  }
  @media screen and (min-width: 768px) {
    .sanity-portable-text {
      padding: 0 1.2rem;
    }
    h6 {
      margin-bottom: 3rem;
    }
  }
  @media screen and (min-width: 1024px) {
    article {
      grid-column: 3 / span 10;
    }
  }
  @media screen and (min-width: 1500px) {
    article {
      grid-column: 4 / span 8;
    }
  }
`;

const StyledTabCarousel = styled(StandardContainer)`
  grid-column: 1 / span 14;
  grid-row: 3;
  overflow: hidden;
  > article {
    display: flex;
    cursor: grab;
    margin-left: -1rem;
    > div {
      width: 100%;
      flex: 0 0 auto;
      padding: 0 0 0 1rem;

      * {
        font-family: var(--headingFont);
        line-height: 1.75;
        font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1800 - 320)));
      }
      a {
        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1800 - 320)));
      }
    }
  }
  @media screen and (min-width: 768px) {
    grid-column: 2 / span 12;
    > article {
      cursor: default;
      padding: 0;
    }
  }
  @media screen and (min-width: 1024px) {
    grid-column: 3 / span 10;
  }
`;
const spring = {
  type: "spring",
  stiffness: 500,
  damping: 70,
};

const StyledNav = styled.nav`
  border-top: 1px solid rgb(12 68 37 / 30%);
  grid-column: 1 / span 14;
  grid-row: 2;
  margin-bottom: 2rem;
  > article {
    display: flex;
    margin-left: -0.5rem;
    margin-top: -2px;
    > div {
      flex: 0 0 auto;
      padding: 0 0.5rem;
    }
    button {
      padding: 1rem 2rem;
      position: relative;
      width: 50%;
      font-family: var(--headingFont);
      font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1800 - 320)));
      transition: all 0.3s;
      border-top: solid 3px transparent;
      > div:nth-of-type(1) {
        transition: all 0.3s;
      }
      > div:nth-of-type(2) {
        transition: all 0.3s;
        position: absolute;
        height: 63px;
        line-height: 63px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        font-weight: bold;
      }
      &.active {
        border-top: solid 3px var(--green);
        > div:nth-of-type(1) {
          /* opacity: 0; */
          font-weight: bold;
        }
        > div:nth-of-type(2) {
          /* opacity: 1; */
        }
      }
      > span {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100px;
        background: var(--green1);
        display: block;
        z-index: 0;
      }
    }
  }
  @media screen and (min-width: 768px) {
    grid-column: 2 / span 12;
    justify-content: center;
    margin-bottom: 4rem;
  }
`;

const TabItem = ({ _rawTextContent, key }) => {
  return (
    <div key={key}>
      <SanityPortableText centerText text={_rawTextContent} />
    </div>
  );
};

// Animation
const variants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 30,
  },
};

export const TabbedContent = ({ heading, items, spacing }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0.3,
  });

  const { width } = useWindowSize();

  const { padTop, padBot } = spacing || {};

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [carouselRef, carouselEmbla] = useEmblaCarousel({
    draggable: width > 767 ? false : true,
  });

  const [navRef, navEmbla] = useEmblaCarousel({
    containScroll: width > 767 ? "keepSnaps" : "",
    selectedClass: "",
    align: "center",
    inViewThreshold: 1,
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!carouselEmbla || !navEmbla) return;
      if (navEmbla.clickAllowed()) carouselEmbla.scrollTo(index);
    },
    [navEmbla, carouselEmbla]
  );

  const onSelect = useCallback(() => {
    if (!carouselEmbla || !navEmbla) return;
    setSelectedIndex(carouselEmbla.selectedScrollSnap());
    navEmbla.scrollTo(carouselEmbla.selectedScrollSnap());
  }, [carouselEmbla, navEmbla, setSelectedIndex]);

  useEffect(() => {
    if (!carouselEmbla) return;
    onSelect();
    carouselEmbla.on("select", onSelect);
  }, [carouselEmbla, onSelect]);

  return (
    <StyledTabbedContent
      ref={ref}
      singleItem
      wrapped
      padBottom={padBot}
      padTop={padTop}
    >
      <article>
        <motion.div
          animate={inView ? "visible" : "hidden"}
          initial={false}
          variants={variants}
          transition={{
            type: "ease-out",
            damping: 10,
            delay: 0.3,
            duration: 0.5,
          }}
        >
          <StyledHeading uppercase fontReg bold as="h6" center>
            {heading}
          </StyledHeading>
          {items && (
            <>
              <StyledNav ref={navRef}>
                <article>
                  <AnimateSharedLayout>
                    {items.map((item, index) => {
                      const isActive = selectedIndex === index;
                      return (
                        <motion.button
                          item={item}
                          key={index}
                          animate={{ opacity: isActive ? 1 : 1 }}
                          onClick={() => onThumbClick(index)}
                          className={`${isActive ? "active" : ""}`}
                        >
                          <div>{item.navText}</div>
                          <div>{item.navText}</div>
                          {isActive && (
                            <motion.span
                              layoutId="bg"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={spring}
                            />
                          )}
                        </motion.button>
                      );
                    })}
                  </AnimateSharedLayout>
                </article>
              </StyledNav>
              <StyledTabCarousel ref={carouselRef}>
                <article>
                  {items.map((item, index) => {
                    return <TabItem {...item} key={index} />;
                  })}
                </article>
              </StyledTabCarousel>
            </>
          )}
        </motion.div>
      </article>
    </StyledTabbedContent>
  );
};
