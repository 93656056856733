import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

// Components
import { SanityPortableText } from "../sanity";
import { GridContainer, StyledHeading } from "../common";

const StyledTextBlock = styled(GridContainer)`
  h3 {
    grid-column: 2 / span 12;
  }
  .sanity-portable-text {
    grid-column: 2 / span 12;
    p {
      padding-bottom: 0.7rem;
    }
    @media screen and (min-width: 1024px) {
      grid-template-rows: auto auto;
      &:nth-of-type(1) {
        grid-row: 2 / span 1;
        grid-column: 2 / span 6;
        padding-right: 1rem;
      }
      &:nth-of-type(2) {
        grid-row: 2 / span 1;
        grid-column: 8 / span 6;
        padding-left: 1rem;
      }
      h3 {
        grid-row: 1 / span 1;
        grid-column: 2 / span 12;
      }
    }
  }
`;
// Animation
const variants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 30,
  },
};

export const TextBlockTwoCol = ({
  heading,
  anchorTarget,
  _rawLeftCol,
  _rawRightCol,
  spacing,
}) => {
  const { padTop, padBot } = spacing || {};

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <motion.div
      animate={inView ? "visible" : "hidden"}
      initial={false}
      variants={variants}
      transition={{
        type: "ease-out",
        damping: 10,
        delay: 0.3,
        duration: 0.5,
      }}
    >
      <StyledTextBlock
        id={anchorTarget}
        ref={ref}
        padBottom={padBot}
        padTop={padTop}
      >
        {heading && (
          <StyledHeading as="h3" size="h4" mBot>
            {heading}
          </StyledHeading>
        )}
        {_rawLeftCol && <SanityPortableText text={_rawLeftCol} />}
        {_rawRightCol && <SanityPortableText text={_rawRightCol} />}
      </StyledTextBlock>
    </motion.div>
  );
};
