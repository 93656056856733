import React from "react";
import styled from "styled-components";
import { match } from "styled-is";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

// Components
import { StyledHeading, GridContainer } from "../common";

import { SanityPortableText } from "../sanity";

const StyledTextBanner = styled(GridContainer)`
  padding: 6rem 0;
  h2 {
    font-size: calc(30px + (52 - 30) * ((100vw - 320px) / (1800 - 320)));
    grid-column: 2 / span 12;
  }
  section {
    grid-column: 2 / span 12;
  }
  @media screen and (min-width: 768px) {
    padding: 8rem 0;
    h2 {
      grid-column: 2 / span 5;
      display: flex;
      align-self: center;
      justify-self: center;
    }
    section {
      grid-column: 7 / span 7;
    }
    ${match("stacked", true)`
      h2 {
        grid-column: 2 / span 12;
      }
      section {
        grid-column: 2 / span 12;
      }
    `}
  }
  @media screen and (min-width: 1024px) {
    padding: 16vh 0;
    h2 {
      grid-column: 2 / span 6;
    }
    section {
      grid-column: 8 / span 6;
    }
    ${match("stacked", true)`
      h2 {
        grid-column: 2 / span 12;
      }
      section {
        grid-column: 2 / span 12;
      }
    `}
  }
`;
// Animation
const variants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const HeadingText = ({ stacked, heading, _rawTextContent, spacing }) => {
  const { padTop, padBot } = spacing || {};

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <>
      <StyledTextBanner
        stacked={stacked}
        padBottom={padBot}
        padTop={padTop}
        ref={ref}
      >
        <AnimatePresence exitBeforeEnter>
          {heading && (
            <StyledHeading as="h2">
              <motion.div
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={variants}
                transition={{
                  type: "ease-out",
                  damping: 10,
                  delay: 0.3,
                  duration: 0.5,
                }}
              >
                {heading}
              </motion.div>
            </StyledHeading>
          )}
          <section>
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={variants}
              transition={{
                type: "ease-out",
                damping: 10,
                delay: 0.3,
                duration: 0.5,
              }}
            >
              {_rawTextContent && <SanityPortableText text={_rawTextContent} />}
            </motion.div>
          </section>
        </AnimatePresence>
      </StyledTextBanner>
    </>
  );
};
