import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

// Components
import { SanityPortableText } from "../sanity";
import { GridContainer } from "../common";

const StyledTextBlock = styled(GridContainer)`
  .sanity-portable-text {
    p {
      padding-bottom: 0.7rem;
    }
  }
`;
// Animation
const imageVariants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 30,
  },
};

export const TextBlock = ({
  anchorTarget,
  _rawTextContent,
  centerText,
  spacing,
}) => {
  const { padTop, padBot } = spacing || {};

  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <StyledTextBlock
      id={anchorTarget}
      ref={ref}
      singleItem
      wrapped
      padBottom={padBot}
      padTop={padTop}
    >
      <article>
        <motion.div
          animate={inView ? "visible" : "hidden"}
          initial={false}
          variants={imageVariants}
          transition={{
            type: "ease-out",
            damping: 10,
            delay: 0.3,
            duration: 0.5,
          }}
        >
          {_rawTextContent && (
            <SanityPortableText
              centerText={centerText}
              text={_rawTextContent}
            />
          )}
        </motion.div>
      </article>
    </StyledTextBlock>
  );
};
