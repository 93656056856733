import React from "react";
import styled from "styled-components";

import { GridContainer, StyledHeading } from "../common";

const StyledQuote = styled(GridContainer)`
  article {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    h5 {
      margin-bottom: 30px;
    }
  }
`;

export const Quote = ({ spacing, credit, text }) => {
  const { padTop, padBot } = spacing || {};
  return (
    <StyledQuote singleItem wrapped padBottom={padBot} padTop={padTop}>
      <article>
        {text && (
          <StyledHeading as="h5" size="h5" fontReg bold bodyColor>
            "{text}"
          </StyledHeading>
        )}
        {credit && (
          <StyledHeading as="h6" size="h6" fontReg bodyColor>
            {credit}
          </StyledHeading>
        )}
      </article>
    </StyledQuote>
  );
};
