import React from "react";
import styled from "styled-components";
// import { AnchorLink } from "gatsby-plugin-anchor-links"

// Styles
const StyledAnchorLink = styled.a`
  color: inherit;
  font-weight: 500 !important;
  text-decoration: underline;
  font-family: var(--bodyFont) !important;
`;

export const InternalAnchorLink = ({ page, linkText, ...props }) => {
  return (
    <StyledAnchorLink {...props} href={"/" + page}>
      {linkText}
    </StyledAnchorLink>
  );
};
