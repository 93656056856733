import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

// Components
import { SanityPortableText } from "../sanity";
import { GridContainer, StyledHeading } from "../common";

const StyledPartners = styled(GridContainer)`
  article {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    h4 {
      font-weight: 500;
      margin-bottom: 30px;
    }
  }
`;

const PartnerItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  section {
    margin: 15px 0;
    width: 80%;
    max-width: 180px;
    img,
    svg {
      width: 100%;
      height: auto;
    }
  }
  @media screen and (min-width: 767px) {
    margin-top: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    section {
      margin: 25px 0;
    }
  }
  @media screen and (min-width: 1900px) {
    section {
      max-width: 200px;
    }
  }
`;

export const Partners = ({ heading, _rawTextContent, spacing, items }) => {
  const { padTop, padBot } = spacing || {};

  return (
    <StyledPartners singleItem wrapped padBottom={padBot} padTop={padTop}>
      <article>
        {heading && (
          <StyledHeading as="h4" size="h6" fontReg>
            {heading}
          </StyledHeading>
        )}
        {_rawTextContent && <SanityPortableText text={_rawTextContent} />}
        {items && (
          <PartnerItems>
            {items.map((item) => {
              const { image, url } = item;
              return (
                <section>
                  {url ? (
                    <a href={`${url}`} target="_blank" rel="noreferrer">
                      {image && <Img fluid={image.asset.fluid} />}
                    </a>
                  ) : (
                    <>{image && <Img fluid={image.asset.fluid} />}</>
                  )}
                </section>
              );
            })}
          </PartnerItems>
        )}
      </article>
    </StyledPartners>
  );
};
