import React from "react";
import styled from "styled-components";

import useFormValidation from "../../hooks/useFormValidation";
import { convertToKebabCase } from "../../utils/helpers";

import { StyledInput, StyledHeading, StandardContainer } from "../common";
import { StyledButton } from "../common";
import { SanityPortableText } from "../sanity";

const StyledNetlifyFormEmbed = styled.form`
  > section {
    text-align: center;
    margin: 4rem 0;
  }
  > article {
    display: grid;
    grid-row-gap: 1rem;
    input,
    textArea {
      display: flex;
      width: 100%;
    }
  }
  button.submit {
    font-size: var(--h7);
    width: auto;
    margin: 1rem 0 0;
    float: right;
    padding: 0.5rem 0 0;
  }
`;

export const NetlifyFormEmbed = ({ formFields, title, successText }) => {
  const formName = convertToKebabCase(title);

  const { handleSubmit, handleChange, success, formError } = useFormValidation(
    {}
  );
  return (
    <StyledNetlifyFormEmbed
      name={formName}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      subject={`Website Enquiry - ${title}`}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value={formName} />
      <p hidden>
        <label>
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      {success ? (
        <section>
          {successText ? (
            <SanityPortableText text={successText} />
          ) : (
            <StyledHeading>Thank you, we will be in touch.</StyledHeading>
          )}
        </section>
      ) : (
        <article>
          {formFields.map(({ required, inputType, label }) => {
            const fieldName = convertToKebabCase(label);
            return inputType !== "textArea" ? (
              <div>
                <StyledInput
                  type={inputType || "text"}
                  required={required || false}
                  placeholder={label}
                  name={fieldName}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div>
                <StyledInput
                  as="textarea"
                  required={required || false}
                  placeholder={label}
                  name={fieldName}
                  className="textarea"
                  onChange={handleChange}
                />
              </div>
            );
          })}
          {formError && <span>{formError}</span>}
          <StandardContainer>
            <StyledButton className="submit" type="submit" flex>
              Submit
            </StyledButton>
          </StandardContainer>
        </article>
      )}
    </StyledNetlifyFormEmbed>
  );
};
