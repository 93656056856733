import React, { useRef } from "react";

import FluidVideo from "../media/fluidVideo";

export const VideoBlock = ({ key, video }) => {
  const imgRef = useRef();

  return (
    <div>
      {/* <div>VideoBlock</div> */}
      <div>
        <div ref={imgRef}>
          {video && (
            <FluidVideo
              url={video}
              width="100%"
              //   className="xl:absolute object-cover h-full w-full top-0 left-0"
              playing={true}
              loop={true}
              muted={true}
              controls={true}
              playsinline={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};
