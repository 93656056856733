import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";

// Components
import { FlexContainer } from "../common";

import { SanityPortableText } from "../sanity";

const StyledBannerLarge = styled(FlexContainer)`
  height: 100vh;
  position: relative;
  align-content: center;
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  section {
    padding: 0 1rem;
    z-index: 1;
    p {
      color: white;
      font-family: var(--headingFont);
      font-size: var(--h2);
      line-height: 1.4;
    }
  }
  a {
    z-index: 1;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
  }
  @media screen and (min-width: 768px) {
    section {
      padding: 0 3rem;
      padding-top: 134px;
    }
  }
  @media screen and (min-width: 1024px) {
    height: 100vh;
    section {
      padding-top: 156px;
    }
  }
`;

export const BannerHero = ({ _rawHeading, link, image }) => {
  return (
    <>
      <StyledBannerLarge>
        {image && <Img fluid={image.asset.fluid} />}
        <section>
          {_rawHeading && <SanityPortableText text={_rawHeading} />}
          {link && (
            <>
              {link.page ? (
                <Link to={link.page.main.slug.current}>{link.linkText}</Link>
              ) : (
                <AnchorLink href={`#bannerAnchor`}>{link.linkText}</AnchorLink>
              )}
            </>
          )}
        </section>
      </StyledBannerLarge>
      <div id="bannerAnchor"></div>
    </>
  );
};
